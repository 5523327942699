/* eslint-disable class-methods-use-this */
import storage from "./index";

const VIRAL_STORAGE_KEY = "viralReferralSlugs";

class ViralReferralSlugList {
  async getRawJSONString(): Promise<string> {
    return storage.getItem(VIRAL_STORAGE_KEY, { crossDomain: true });
  }

  async getList(): Promise<string[] | undefined> {
    try {
      const rawJSONString = await this.getRawJSONString();

      const viralReferralSlugList: string[] = JSON.parse(rawJSONString);

      return viralReferralSlugList;
    } catch (error) {
      console.log("Failed to parse");
      return undefined;
    }
  }

  async append(slug: string): Promise<void> {
    try {
      const list = await this.getList();
      if (!list) {
        const value = JSON.stringify([slug]);
        await storage.setItem(VIRAL_STORAGE_KEY, value, { crossDomain: true });
      }

      list.push(slug);
      const uniqueViralReferralList = [...new Set(list)];
      const value = JSON.stringify(uniqueViralReferralList);

      await storage.setItem(VIRAL_STORAGE_KEY, value, { crossDomain: true });
    } catch (error) {
      console.log("Failed to parse");
    }
  }
}

const viralReferralSlugList = new ViralReferralSlugList();

export default viralReferralSlugList;
